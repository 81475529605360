
@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@400;500;700&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
  height: 100vh;
  overflow: hidden;
}

@layer utilities {
    /* Chrome, Safari and Opera */
    .no-scrollbar::-webkit-scrollbar {
      display: none;
    }

    .no-scrollbar {
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
}

@layer base {
    
  html {
    font-family: 'Poppins', sans-serif;
    font-size: 12.8px;
  }
  
}

.toggle-bg:after {
    @apply absolute top-0.5 left-0.5 bg-gray-120 border border-transparent rounded-full h-5 w-5 transition shadow-sm;
    content: '';
};
input:checked + .toggle-bg:after {
    transform: translateX(100%);
    @apply border-blue-80 bg-blue-80 ;
}
input:checked + .toggle-bg {
    /* @apply bg-red-200 border-red-200; */
}

input:checked + .checkbox {
  /* background-color: red; */
  /* background: url("../images/icons/line-check.svg") center; */
  /* @apply bg-gray-70; */
}

.inapp li:has(a[aria-current="page"]) {
  /* @apply  pb-[8px]  text-gray-30 ; */
}

.amount-input > .relative {
  @apply border border-transparent 
}
.amount-input > .relative > input {
  @apply !border-none text-4xl placeholder:text-4xl focus:outline-none text-center focus:placeholder:text-transparent
}

button[data-state="open"] svg:last-child:not(:only-child) {
  @apply rotate-180 transition-all
}
button[data-state="closed"] svg:last-child:not(:only-child){
  @apply  transition-all
}

.chart-select button {
  @apply px-7
}
.chart-select:first-child button {
  @apply pt-8 pb-2
}
.chart-select:last-child button {
  @apply pb-7 pt-2
}
.chart-select:not(:first-child,:last-child) button {
  @apply py-2
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* styles.css */
.HoverCardContent {
  animation-duration: 0.6s;
  animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
}
.HoverCardContent[data-side='top'] {
  animation-name: slideUp;
}
.HoverCardContent[data-side='bottom'] {
  animation-name: slideDown;
}

@keyframes slideUp {
  from {
    opacity: 0;
    transform: translateY(10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideDown {
  from {
    opacity: 0;
    transform: translateY(-10px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

.progressbar{
  height: 0.25rem;
  width: 100%;
  background-color: transparent;
}



.ScrollAreaRoot {
  width: 100%;
  height: 100%;
}

.ScrollAreaViewport {
  width: 100%;
  height: 100%;
}

.ScrollAreaScrollbar {
  width: 4px;
  padding: 5px 2px;
}

.ScrollAreaThumb {
  background: rgba(0, 0, 0, 0.3);
  border-radius: 3px;
}
.__floater__open{
padding: 0 !important;
}

@keyframes bouncing {
  0%, 100%, 80% {
    transform: scale(0);
  }
  40% {
    transform: scale(1);
  }
}

.animate-bouncing {
  animation: bouncing 1.4s infinite ease-in-out;
}


*{
  /* outline: 1px solid red */
}